import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react"
import dateFormat from 'dateformat';
import {
  Button,
  Navbar,
  Nav,
  Form,
  Container,
  FormControl,
  Row,
  Col,
} from "react-bootstrap"
import Slider from "react-slick"

// Images
import ImgPerson from "../../../images/person.jpg"
import blogImg1 from "../../../images/home/blog-img-01.webp"
import blogImg2 from "../../../images/home/blog-img-02.webp"
import blogImg3 from "../../../images/home/blog-img-03.webp"
import blogImg4 from "../../../images/home/blog-img-04.webp"
import ContactCard from "../../ContactCard/ContactCard"
import "./FilterBlockCate.scss"

import {InsightsCate} from "../../../queries/common_use_query"
import { removeDataAttributes } from "../../../comQueryStructure";

const LatestBlogsbyCategory = (props) => {
  let categories = [];
  { props.Filter_by_Tag.length > 0 &&
    props.Filter_by_Tag.map(item => categories.push(item.category_name))
   }
 
  const {loading, error, data} = InsightsCate("", 200);
  const otherInsightData = removeDataAttributes(data?.insights)

  let cateInsight = []
   otherInsightData?.length > 0 && otherInsightData?.map(item => {
      if(item?.categories?.length>0)   {
         item?.categories?.some(its=> {
          //console.log("its", its.category_name)
            if(categories?.includes(its.category_name)) {
              cateInsight.push(item)
              ///console.log("it", item)
                // return item
            }
         }
    );
      } 
  })

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: cateInsight && cateInsight.length > 3 ? 5 :  cateInsight && cateInsight.length,
    slidesToScroll: 1,
    infinite:  cateInsight && cateInsight && cateInsight.length > 3 ? true : false,
    arrows: false,
    mobileFirst: true,
    autoplay:true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: cateInsight && cateInsight.length > 3 ? 4 :  cateInsight && cateInsight.length,
          slidesToScroll: 1,
          infinite:  cateInsight && cateInsight && cateInsight.length > 3 ? true : false,
          dots: true,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow:  cateInsight && cateInsight.length > 2 ? 3 :  cateInsight && cateInsight.length,
          slidesToScroll: 1,
          infinite:  cateInsight && cateInsight.length > 3 ? true : false,
          dots: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  }

  //console.log("cateInsight", cateInsight)
 
  return (
      
      <div className={`other_insights-category filter-block other_insights ${cateInsight && cateInsight.length < 4 ? 'other_sml_wdth' : ''}`}>

      <Container>
        <Row>
          <Col lg="12">
            <div className="filter-block-title">
               <div className="animated">
                <h2>Other insights that may interest you</h2>
               </div>
            </div>


            <div className="filter-block-list text-center text-xl-left">
              <Slider {...settings}>
                {
                   cateInsight && cateInsight?.length > 0 && cateInsight?.slice(0,10).map((item, index) => {
                    var date_of_item =item.Date && dateFormat(item.Date, "dd mmmm yyyy");
                    let category_name = []
                    if(item?.categories?.length>0)   {
                      item?.categories?.map(cate => {
                        category_name.push(cate.category_name)
                      })
                    }
                    var split_tags = item.Tag ? item.Tag.split(', ') : '';
                    return(
                       <div className="animated">
                        <ContactCard
                          cardImg={item.Tile_Image}
                          cardTitle={item.Title}
                          date={date_of_item}
                          cardTag={category_name[0].replaceAll('_', ' ')}
                          link_url={item.URL}
                          externalLink={item?.External_Link}
                          imagename={"insight.Tile_Image.tile_image"}
                          article_id={item.id} 
                          imagetransforms={item.ggfx_results}
                        />
                       </div>
                    )
                  })
                }
              </Slider>


            </div>
          </Col>
        </Row>
      </Container>
    </div> 


  
  
  )
}

export default LatestBlogsbyCategory
